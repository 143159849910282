import { useMemo } from 'react';
import { cn } from '@divlab/divanui';

import * as CatalogFiltrator from '@Stores/CatalogFiltrator';
import Img from '@UI/Img';
import Link from '@Navigation/Link';
import { useDeps } from '@Contexts/DI';
import useNavigation from '@Navigation/useNavigation';
import styles from './Filter.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { FiltersValue } from '@Types/Filters';

interface FilterProps extends HTMLAttributes<HTMLAnchorElement> {
  value: FiltersValue;
}

const Filter: FC<FilterProps> = (props) => {
  const { className, value, ...restProps } = props;
  const { pathname } = useNavigation();
  const { parameterValues } = CatalogFiltrator.useSelected();
  const { analytics } = useDeps();

  const selected = useMemo(() => {
    return parameterValues.some((parameterValue) => parameterValue === value);
  }, [parameterValues, value]);

  const link = useMemo(() => {
    const searchString = CatalogFiltrator.createUrlForValues([value]);

    return `${pathname}${searchString}`;
  }, [pathname, value]);

  const handleClick = () => {
    analytics.dispatchEvent('filters.click', {
      category: 'Product_filter',
      label: value.name,
    });
  };

  return (
    <Link className={cn(styles.wrapper, className)} to={link} onClick={handleClick} {...restProps}>
      <div className={cn(styles.container, { [styles.selected]: selected })}>
        <Img className={styles.image} src={value.meta.icon} />
        <div className={styles.text}>
          <div className={styles.header}>
            <div className={styles.title}>{value.name}</div>
            <div className={styles.check}>
              <svg className={styles.mark} viewBox='0 0 13 10' xmlns='http://www.w3.org/2000/svg'>
                <path d='M12 1.5L5.42857 8L1.5 4.5' />
              </svg>
            </div>
          </div>
          {!!value.meta?.description && (
            <div className={styles.subtitle}>{value.meta.description}</div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default Filter;
