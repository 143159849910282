import { memo, useMemo, useRef } from 'react';
import { cn, Gallery } from '@divlab/divanui';

import Link from '@Navigation/Link';
import IconArrowTriple from './elements/ArrowTriple';
import styles from './InformationTabsNavigation.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { IGalleryRef } from '@divlab/divanui';
import type { NavigationItemData } from '@Types/NavigationItem';

export interface InformationTabsNavigationProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  navigation: NavigationItemData[];
}

const icons = {
  arrowTriple: IconArrowTriple,
};

const InformationTabsNavigation: FC<InformationTabsNavigationProps> = (props) => {
  const { navigation, ...restProps } = props;
  const galleryRef = useRef<IGalleryRef>(null);

  const initialSlideIndex = useMemo(() => {
    return navigation.findIndex((link) => link.active);
  }, [navigation]);

  const handleSlideTo = (index: number) => {
    if (!galleryRef.current) return;

    galleryRef.current.slideTo(index);
  };

  return (
    <nav {...restProps}>
      <Gallery
        className={styles.gallery}
        cnSlide={styles.slide}
        initialSlideIndex={initialSlideIndex || 0}
        ref={galleryRef}
      >
        {navigation.map((item, index) => {
          const IconBefore = icons[item.before];

          return (
            <Link
              key={item.href}
              data-testid={item.testId}
              to={item.href}
              className={cn(styles.navigationLink, { [styles.active]: item.active })}
              onClick={() => handleSlideTo(index)}
            >
              {!!IconBefore && (
                <IconBefore
                  active={item.active}
                  className={cn({
                    [styles.arrowTriple]: item.before === 'arrowTriple',
                  })}
                />
              )}
              {item.name}
            </Link>
          );
        })}
      </Gallery>
    </nav>
  );
};

export default memo(InformationTabsNavigation);
