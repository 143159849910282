import { cn, Gallery } from '@divlab/divanui';

import Filter from './Filter';
import styles from './CollectionFilters.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { FiltersValue } from '@Types/Filters';

interface CollectionFiltersProps extends HTMLAttributes<HTMLDivElement> {
  values: FiltersValue[];
}

const CollectionFilters: FC<CollectionFiltersProps> = (props) => {
  const { className, values, ...restProps } = props;

  return (
    <Gallery
      className={cn(styles.gallery, className)}
      cnSlide={styles.slide}
      scrollbar
      hasEmptyScrollbar
      {...restProps}
    >
      {values.map((value) => (
        <div key={value.name}>
          <Filter value={value} />
        </div>
      ))}
    </Gallery>
  );
};

export default CollectionFilters;
