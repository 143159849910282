import { memo, useCallback, useMemo } from 'react';
import { Gallery, Price } from '@divlab/divanui';
import { InView } from 'react-intersection-observer';

import Section from '@Components/Section';
import ConstructorStub from '@Components/ConstructorStub';
import CardInView from '@Components/CardInView';
import useMedias from '@Hooks/useMedias';
import useRenderType from '@Hooks/useRenderType';
import { useDeps } from '@Contexts/DI';
import styles from './SectionCatalog.module.css';

import type { ProductModel, ConstructorStubData } from '@Types/Category';
import type { ProductData } from '@Types/Product';
import type { RenderProductParams } from '@Types/Catalog';
import type { FC, HTMLAttributes, ReactElement, MouseEvent } from 'react';

export type RenderProduct = (params: RenderProductParams) => ReactElement;

export type SectionItem = ProductData | ConstructorStubData;

export interface SectionProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  analyticsTitle: string;
  productModel: ProductModel;
  items: SectionItem[];
  offset: number;
  page: number;
  renderProduct: RenderProduct;
  onChangeVisibleRow?: (page: number) => void;
}

const SectionCatalog: FC<SectionProps> = (props) => {
  const {
    analyticsTitle,
    productModel,
    items,
    offset,
    renderProduct,
    page,
    onChangeVisibleRow,
    ...restProps
  } = props;
  const { isMobileM } = useMedias();
  const { isCSR, isSSR } = useRenderType();
  const { analytics } = useDeps();

  const handleClickProductLink = useCallback(
    (e: MouseEvent, data: { product: ProductData; position: number }) => {
      const { product, position } = data;

      analytics.dispatchEvent('card.click', {
        cardType: 'product',
        listTitle: analyticsTitle,
        id: product.id,
        card: product,
        position,
      });
    },
    [analyticsTitle, analytics],
  );

  const renderItems = useCallback(() => {
    return items.map((item, index) => {
      const isStub = item.id === 'stub';
      const key = isStub ? index : item.id;

      return (
        <div key={key}>
          {isStub ? (
            <ConstructorStub stub={item as ConstructorStubData} productId={productModel.id} />
          ) : (
            <CardInView
              listTitle={analyticsTitle}
              card={item as ProductData}
              position={offset + index}
              cardType='product'
              id={item.id}
              preventHandleClick
            >
              {renderProduct({
                product: item as ProductData,
                imageLazyLoading: isMobileM ? offset + index > 1 : offset !== 0,
                onClickProductLink: (e: MouseEvent) =>
                  handleClickProductLink(e, {
                    product: item as ProductData,
                    position: offset + index,
                  }),
              })}
            </CardInView>
          )}
        </div>
      );
    });
  }, [
    items,
    productModel.id,
    analyticsTitle,
    offset,
    renderProduct,
    isMobileM,
    handleClickProductLink,
  ]);

  const needPrice = useMemo(() => {
    if (isSSR) return true;
    if (isCSR && productModel.isActive) return true;

    return false;
  }, [isCSR, isSSR, productModel.isActive]);

  return (
    <Section
      {...restProps}
      title={
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{productModel.name}</div>
          {productModel.priceMin && (
            <div className={styles.priceMin}>
              {/* Требование SEO чтобы от сервера всегда приходила цена,
              и только на клиенте изменялась на Нет в наличии */}
              {needPrice && (
                <>
                  {`от `}
                  <Price price={productModel.priceMin} />
                </>
              )}
            </div>
          )}
        </div>
      }
    >
      <InView
        as='div'
        className={styles.container}
        onChange={(inView) => inView && onChangeVisibleRow(page)}
      >
        {isMobileM ? (
          <Gallery cnSlide={styles.slide} spaceBetween={20} scrollbar>
            {renderItems()}
          </Gallery>
        ) : (
          <div className={styles.list}>{renderItems()}</div>
        )}
      </InView>
    </Section>
  );
};

export default memo(SectionCatalog);
