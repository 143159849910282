import { memo, useCallback, useRef, useMemo } from 'react';

import SectionCatalog from './elements/SectionCatalog';
import styles from './ProductSectionsCatalog.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { UseInfiniteQueryResult, InfiniteData } from '@tanstack/react-query';
import type { CatalogData } from '@Types/Catalog';
import type { ProductData } from '@Types/Product';
import type { RenderProduct, SectionItem } from './elements/SectionCatalog';

export interface ProductSectionsCatalogProps extends HTMLAttributes<HTMLDivElement> {
  analyticsTitle: string;
  category: UseInfiniteQueryResult<InfiniteData<CatalogData>>;
  autoload?: boolean;
  renderProduct: RenderProduct;
  onChangeVisibleRow?: (page: number) => void;
}

const ProductSectionsCatalog: FC<ProductSectionsCatalogProps> = (props) => {
  const { analyticsTitle, category, renderProduct, onChangeVisibleRow, ...restProps } = props;
  const ref = useRef<HTMLDivElement>();

  const products = useMemo(() => {
    let result: ProductData[] = [];

    category.data.pages.forEach((page) => {
      result = result.concat(page.products);
    });

    return result;
  }, [category.data]);

  const getProductsBySection = useCallback(
    (modelId: number, isActive: boolean) => {
      if (isActive) {
        return products.filter((product) => product.modelId === modelId && product.isActive);
      }
      return products.filter((product) => product.modelId === modelId && !product.isActive);
    },
    [products],
  );

  let cardIndexOffset = 0;

  return (
    <div {...restProps} ref={ref}>
      <div className={styles.sections}>
        {category.data.pages.map((page) => {
          return page.productsModel.map((productModel) => {
            const sectionProducts = getProductsBySection(productModel.id, productModel.isActive);
            const items: SectionItem[] = [...sectionProducts];

            if (items.length < 1) return null;
            if (productModel.constructor)
              items.push({
                id: 'stub',
                ...productModel.constructor,
                classifications: productModel.classifications,
                configurator: productModel.configurator,
              });

            const offsetBeforeChange = cardIndexOffset;
            cardIndexOffset += items.length;

            return (
              <SectionCatalog
                key={productModel.id}
                productModel={productModel}
                analyticsTitle={analyticsTitle}
                items={items}
                renderProduct={renderProduct}
                offset={offsetBeforeChange}
                page={page.page}
                onChangeVisibleRow={onChangeVisibleRow}
              />
            );
          });
        })}
      </div>
    </div>
  );
};

export default memo(ProductSectionsCatalog);
